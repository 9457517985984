/* Date picker styles */
@import '~react-datepicker/dist/react-datepicker.css';
.react-datepicker__navigation--previous {
  border-right-color: #cbd5e0;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #a0aec0;
}

.react-datepicker__navigation--next {
  border-left-color: #cbd5e0;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #a0aec0;
}

.react-datepicker__header {
  background: #f7fafc;
}

.react-datepicker__day:hover {
  background: var(--chakra-colors-primary-light);
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--selected:hover {
  background: var(--chakra-colors-primary-dark);
  color: white;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: var(--chakra-colors-primary-main);
  font-weight: normal;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: var(--chakra-colors-primary-main);
  font-weight: normal;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: var(--chakra-colors-primary-main);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: var(--chakra-colors-primary-main);
}

.react-datepicker__close-icon::after {
  background-color: unset;
  border-radius: unset;
  font-size: 1.5rem;
  font-weight: bold;
  color: hsl(0, 0%, 80%);
  height: 20px;
  width: 20px;
}

.react-datepicker__close-icon::after:hover {
  color: hsl(0, 0%, 70%);
}

.react-datepicker__day--outside-month {
  opacity: 0.8;
}
