/* Used by the tooltip in the decsription when 
selecting an activity in the ActivitySelector */
sup:hover .popover-tooltip-content {
  display: inline;
}

sup:hover .popover-tooltip-content {
  display: block !important;
  position: absolute;
  height: fit-content;
  line-height: initial;
  background-color: white;
  padding: 12px;
  border-radius: 5px;
  border: 1px black solid;
  margin: 15px;
}

canvas.chartjs-render-monitor {
  position: relative; /*Force canvas to be on top of other elements in doughnut chart*/
}

.extended-description-table tr td {
  border: 1px solid black;
  padding: 5px;
}
.custom-html p {
  padding: 16px 0px;
}
.custom-html ul,
.custom-html ol {
  padding: 20px;
}

@import 'components/ui/forms/DateInput.css';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin1 {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin2 {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(270deg);
  }
  70% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin3 {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(180deg);
  }
  70% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin4 {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(90deg);
  }
  70% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.4);
  }
  50% {
    transform: scale(0.9);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.draggingSource {
  opacity: 0.3;
}

.dropTarget {
  background-color: rgba(5, 104, 253, 0.08);
}

.placeholderContainer {
  position: relative;
}

.listItem {
  list-style: none;
}
